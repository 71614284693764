
import { ref, watch, computed } from 'vue'
import { axiosInstance } from '@/plugins/axios'

    const inspectionDays = ref([])
const routeLoading = ref(false)
    const currentPage = ref(1)
    const perPage = ref(10)
    const totalRecords = ref()




export const useInspectionDays = () =>
{

    
    const getDays = async () => {
        routeLoading.value = true
        inspectionDays.value = []
  const { data } = await axiosInstance.get(`/v1/inspection-days?page=${currentPage.value}&limit=${perPage.value}&sort[created_at]=desc`)
  inspectionDays.value = data.data
  totalRecords.value = data.metadata.total
  routeLoading.value = false
}
return { getDays, inspectionDays, routeLoading, currentPage, perPage, totalRecords }
}

