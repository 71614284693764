var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"p-0 card-body"},[_c('b-table',{ref:"staffTable",attrs:{"current-page":_setup.currentPage,"busy":_setup.routeLoading,"responsive":"","striped":"","items":_setup.inspectionDays,"fields":_setup.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"my-2 text-center text-secondary"},[_c('strong',[_vm._v("Loading...")])])]},proxy:true},{key:"empty",fn:function(){return [_c('h4',[_vm._v("There are no staff found")])]},proxy:true},{key:"cell(id)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'ShowVehicle',
          params: { vehicleId: data.item.id },
        }}},[_vm._v(" "+_vm._s(data.item.vehicle.brand)+" "+_vm._s(data.item.vehicle.name)+" ")])]}},{key:"cell(vehicle)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.vehicle.registration_number)+" ")]}},{key:"cell(vehicle_id)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.vehicle.seats)+" ")]}},{key:"cell(inspectionSite)",fn:function(data){return [_vm._v(" "+_vm._s(_setup.Capitalize(data.item.inspectionSite.name) ?? 'N/A')+" ")]}},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.date)+" ("+_vm._s(data.item.time)+") ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_setup.moment(data.item.created_at).format('MMMM Do YYYY'))+" ")]}},{key:"cell(partner)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'PartnerDetails',
              params: {
        partnerId: data.item.partner.id,
        accountSid: data.item.partner.account_sid
      }
        }}},[_vm._v(" "+_vm._s(data.item.partner.company_name)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('p',{staticClass:"m-0 py-1 text-white text-[12px] w-fit rounded px-2 font-medium",class:data.item.status == 'active' ? 'bg-[#15CD70]' : data.item.status == 'inactive' ? 'bg-[red]' : 'bg-[#6E717C]'},[_vm._v(_vm._s(data.item.status))])]}}])}),_c('b-pagination',{attrs:{"total-rows":_setup.totalRecords,"per-page":_setup.perPage},model:{value:(_setup.currentPage),callback:function ($$v) {_setup.currentPage=$$v},expression:"currentPage"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }