<template>
  <div class='card'>
    <div class="p-0 card-body">
      <b-table ref="staffTable" :current-page="currentPage" :busy="routeLoading" responsive striped :items="inspectionDays" :fields="fields">
        <template #table-busy>
          <div class="my-2 text-center text-secondary">
            <strong>Loading...</strong>
          </div>
        </template>
        <template #empty>
          <h4>There are no staff found</h4>
        </template>
        <template #cell(id)="data">
          <router-link :to="{
            name: 'ShowVehicle',
            params: { vehicleId: data.item.id },
          }">
            {{ data.item.vehicle.brand }} {{ data.item.vehicle.name }}
          </router-link>
        </template>

        <template #cell(vehicle)="data">
        {{ data.item.vehicle.registration_number }}
        </template>

        <template #cell(vehicle_id)="data">
          {{ data.item.vehicle.seats }}
        </template>

        <template #cell(inspectionSite)="data">
          {{ Capitalize(data.item.inspectionSite.name) ?? 'N/A' }}
        </template>

        <template #cell(date)="data">
          {{ data.item.date }} ({{ data.item.time }})
        </template>
        <template #cell(created_at)="data">
          {{ moment(data.item.created_at).format('MMMM Do YYYY') }}
        </template>
        <template #cell(partner)="data">
          <router-link :to="{
            name: 'PartnerDetails',
                params: {
          partnerId: data.item.partner.id,
          accountSid: data.item.partner.account_sid
        }
          }">
            {{ data.item.partner.company_name }} 
          </router-link>
        </template>
        <template #cell(status)="data">
          <p class=" m-0 py-1 text-white text-[12px] w-fit rounded px-2 font-medium" :class="data.item.status == 'active' ? 'bg-[#15CD70]' : data.item.status == 'inactive' ? 'bg-[red]' : 'bg-[#6E717C]'">{{ data.item.status }}</p>
        </template>
        <!-- <template #cell(updated_at)="data">
          <button v-if="data.item.status == 'active'" class='text-xs text-black bg-transparent border-black rounded outline-none btn' @click='openRestoreModal(data.item)'>Cancel</button>
          <p v-else class="m-0 text-sm ">{{ moment(data.item.created_at).format('MMMM Do YYYY') }}</p>
        </template> -->
      </b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
    </div>

  </div>
</template>

<script setup >
import Swal from 'sweetalert2'
import { ref, watch } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import moment from 'moment'
import { useInspectionDays } from './composable' 
import { Capitalize } from '@/composables/utils'


const showPassword = ref(false)
const showRestoreModal = ref(false)
const payloadDate = {
  loading: ref(false),
  staff_password: ref(''),
}
const selectedRoute = ref(null)


const { currentPage, getDays, routeLoading, inspectionDays, perPage, totalRecords } = useInspectionDays()
getDays()

const fields = [
  { key: 'id', label: 'Vehicle' },
  { key: 'vehicle', label: 'Plate Number' },
  { key: 'vehicle_id', label: 'Capacity' },
  { key: 'inspectionSite', label: 'Inspection Site' },
  { key: 'partner', label: 'Partner' },
  { key: 'created_at', label: 'Date submitted' },
  { key: 'date', label: 'Inspection date and time' },
  // { key: 'status', label: 'Status' },
  // { key: 'suspension_dates', label: 'Suspension Dates' },
  // { key: 'updated_at', label: 'Action' },
]

watch(currentPage, () =>
{
  getDays()
})
</script>

<style scoped></style>
